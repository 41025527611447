import React, { Fragment, useState, useEffect } from "react";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';
//import CircularProgress from '@mui/material/CircularProgress';
//import { styled } from '@mui/material/styles';

import { DataGrid } from '@mui/x-data-grid';
import { frFR as dataGridfrFR } from '@mui/x-data-grid/locales';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import dayjs from 'dayjs';
import "dayjs/locale/fr";

import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";
import { TextError, TextMessage, TextTip } from '#components/TextInformation';
import { useApiReservations } from "#services/apiReservations";
import ScrollButtonToTop from '#components/ScrollButtonToTop'
import ConfirmDelete from '#components/ConfirmDelete';
import useFunctions from '#utils/functions';


export function Reservations() {

    const { user } = useAuth();
    const api = useApiReservations();

    const { formatDate } = useFunctions();

    // const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);

    // supprime une réservation -----------------------------------------------------------
    const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false);
    const [contentTextDeleteDialog, setContentTextDeleteDialog] = useState('');
    const [urvIdToDelete, setUrvIdToDelete] = useState(0);

    const handleRemoveClick = (e, row) => {
        e.stopPropagation();
        let txt = 'Voulez-vous vraiment supprimer la réservation de ' + row.urv_usr_firstname + ' ' + row.urv_usr_name;
        txt += ' pour un ' + row.urv_far_lib + ' ?';
        txt += "<br /><br />Date : " + formatDate(row.urv_date);
        txt += "<br />Horaire : " + row.urv_tis_lib;

        setUrvIdToDelete(row.urv_id);
        setContentTextDeleteDialog(txt);
        setIsOpenConfirmDeleteDialog(true);
    };

    const handleNoDelete = () => {
        setUrvIdToDelete(0);
        setIsOpenConfirmDeleteDialog(false);
        setContentTextDeleteDialog('');
    }
    const handleYesDelete = () => {
        setIsWorking(true);
        let keyTab = refCodeTabs[activeTab].code;

        api.deleteData(urvIdToDelete)
            .then((json) => {
                setIsWorking(false);
                if (json.returnCode === 0) {
                    setUrvIdToDelete(0);
                    setIsOpenConfirmDeleteDialog(false);
                    setContentTextDeleteDialog('');
                    if (json.returnDataCount === 1) {
                        // refresh
                        let tempL = { ...isTabLoaded };
                        tempL[keyTab] = false;
                        setIsTabLoaded(tempL);
                    }
                    setErrorMessage(null);
                } else {
                    setUrvIdToDelete(0);
                    setIsOpenConfirmDeleteDialog(false);
                    setContentTextDeleteDialog('');
                    setErrorMessage(json.returnMsg || 'Erreur suppression');
                }
            });
    }
    //--------------------------------------------------------------
    // définition des listes 

    dayjs().locale('fr');

    const refCodeTabs = [
        { code: 'today', lib: "Aujourd'hui", comment: "Réservation du " + formatDate(dayjs()) }
        , { code: 'thisweek', lib: "Cette semaine", comment: "Semaine du " + formatDate(dayjs().startOf('week')) + " au " + formatDate(dayjs().endOf('week')) }
        , { code: 'nextweek', lib: "Semaine prochaine", comment: "Semaine du " + formatDate(dayjs().startOf('week').add(7, 'day')) + " au " + formatDate(dayjs().endOf('week').add(7, 'day')) }
        , { code: 'future', lib: "A venir", comment: "A partir de demain" }
        , { code: 'history', lib: "Historique" }
    ]

    const baseColumns = [
        { field: 'urv_id', headerName: 'ID', width: 60, type: 'number' },
        {
            field: 'urv_date', headerName: 'Date', width: 100, type: 'date'
            , valueFormatter: (params) => {
                return formatDate(params.value);
            }
        },
        { field: 'urv_tis_lib', headerName: 'Horaire', type: 'string', width: 110 },
        { field: 'urv_far_lib', headerName: 'Type bureau', type: 'string', width: 150 },
        { field: 'urv_red_lib', headerName: 'Bureau', type: 'string', width: 120 },
        { field: 'urv_usr_libr', headerName: 'Compte', width: 200 },
        //{ field: 'urv_usr_firstname', headerName: 'Prénom', width: 150},
        //{ field: 'urv_usr_name', headerName: 'Nom', width: 150},
        { field: 'urv_usr_email', headerName: 'Email / Login', width: 250 },
        {
            field: 'actions', disableColumnMenu: true, resizable: false, filterable: false, sortable: false, editable: false, groupable: false, headerName: 'Actions'
            , width: 90, headerAlign: 'center', align: 'center',
            renderCell: (params) => {
                return (
                    <Box>
                        <IconButton sx={{ ml: 0, px: 0, py: 2, my: 0 }} onClick={(e) => handleRemoveClick(e, params.row)} >
                            <RemoveCircleIcon sx={{ typography: { sm: 'h4', xs: 'h4' }, color: red[500] }} />
                        </IconButton>
                    </Box>
                );
            }
        }
    ].map(
        // valeur commune      
        (col) => {
            col.headerClassName = 'app-theme--header';
            col.sortable = false;  // pb disableColumnSorting, on doit l'indiquer pour chaque column !      
            return col;
        }
    );

    // fonction pour init selon les tabs !
    const initFromTabs = (initValue) => {
        let arrayTabs = refCodeTabs.map((x) => x.code);
        let r = {};
        arrayTabs.forEach((tab) => { r[tab] = initValue; });
        return r;
    };

    const columns = initFromTabs(baseColumns);

    //--------------------------------------------------------------            
    const [isTabLoaded, setIsTabLoaded] = useState(initFromTabs(false));
    const [data, setData] = useState(initFromTabs({}));

    //--------------------------------------------------------------        
    const [paginationModel, setPaginationModel] = useState(initFromTabs({
        page: 0,
        pageSize: 25
    }));
    const [filterModel, setFilterModel] = useState(initFromTabs({
        items: []
    }));

    const [rowCount, setRowCount] = useState(initFromTabs(0));

    const onPaginationModelChange = (newPaginationModel) => {
        let keyTab = refCodeTabs[activeTab].code;
        let temp = { ...paginationModel };
        temp[keyTab] = newPaginationModel;
        setPaginationModel(temp);
    };

    const onFilterModelChange = (newFilterModel) => {
        let keyTab = refCodeTabs[activeTab].code;
        let temp = { ...filterModel };
        temp[keyTab] = newFilterModel;
        setFilterModel(temp);
    };

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue, other) => {
        setActiveTab(newValue);
    };

    const getRowId = (data) => {
        return data.urv_id;
    }

    useEffect(() => {
        let keyTab = refCodeTabs[activeTab].code;

        if (user) { // && !isTabLoaded[keyTab]) { 
            setIsWorking(true);
            api.getData(keyTab, paginationModel[keyTab], filterModel[keyTab])
                .then((json) => {
                    setIsWorking(false);
                    let tempL = { ...isTabLoaded };
                    let tempD = { ...data };
                    let tempRC = { ...rowCount }
                    tempL[keyTab] = true;
                    if (json.returnCode === 0) {
                        tempD[keyTab] = json.returnData;
                        tempRC[keyTab] = json.returnTotalDataCount;
                        setErrorMessage('');
                    } else {
                        tempD[keyTab] = {};
                        tempRC[keyTab] = 0;
                        setErrorMessage(json.returnMsg || 'Erreur récupération des réservations');
                    }
                    setData(tempD);
                    setRowCount(tempRC);
                    setIsTabLoaded(tempL);
                });
        }

    }, [user, activeTab, paginationModel, filterModel]);

    //--------------------------------------------------------------        
    const getData = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return (data[key] && data[key].length > 0) ? data[key] : null;
    }
    const getCol = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return (columns[key] && columns[key].length > 0) ? columns[key] : null;
    }
    const getCommentActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].comment;
        return key ? key : '';
    }
    const getRowCountActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return rowCount[key];
    }
    const getPaginationModelActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return paginationModel[key];
    }


    return (
        <Fragment>
            {/* pour la gestion de la suppression */}
            <ConfirmDelete open={isOpenConfirmDeleteDialog} contentText={contentTextDeleteDialog} handleNo={handleNoDelete} handleYes={handleYesDelete} />

            <TitlePage titlePage="Réservations"
                showAddButton="right" tooltipAddButton="Ajouter une réservation" routeAddButton="/Admin/Reservation/Add"
            />

            {/* infoMessage!=='' &&  <TextMessage>{infoMessage}</TextMessage> */}
            {errorMessage !== '' && <TextError>{errorMessage}</TextError>}

            <Container sx={{ pt: 2, pb: 4 }} maxWidth="xl" >

                {/* les tabs  */}
                <Box sx={{ mb: 2, py: 0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                        {
                            refCodeTabs.map((value, index) => (
                                <Tab key={index} label={value.lib} wrapped={true} />
                            ))
                        }
                    </Tabs>
                </Box>
                <TextTip>{getCommentActiveTab(activeTab)}</TextTip>


                {/* les datas */}

                {/*getData(activeTab) && */
                    <DataGrid
                        localeText={dataGridfrFR.components.MuiDataGrid.defaultProps.localeText}
                        loading={isWorking}
                        getRowId={getRowId}
                        rows={getData(activeTab) || []}
                        rowCount={getRowCountActiveTab(activeTab)}
                        columns={getCol(activeTab)}
                        density='compact'
                        disableColumnSelector={true}

                        filterMode="server"
                        onFilterModelChange={onFilterModelChange}

                        pageSizeOptions={[10, 25, 50, 100]}
                        paginationModel={getPaginationModelActiveTab(activeTab)}
                        onPaginationModelChange={onPaginationModelChange}
                        paginationMode="server"
                    />
                }

                {!getData(activeTab) && <TextMessage>Aucune réservation</TextMessage>}

            </Container>

            <ScrollButtonToTop />

        </Fragment>
    );
};
