import React  from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from "#pages/Root";
import {Entreprise} from "#pages/Entreprise";
import {Prestataires} from "#pages/Prestataires";
import {Services} from "#pages/Services";
import {Photos} from "#pages/Photos";
import {Contact} from "#pages/Contact";

import {RequestPricing} from "#pages/RequestPricing" ;

import {MentionsLegales} from "#pages/MentionsLegales";
import {Rgpd} from "../pages/Rgpd";

// --------------------------------------------------------------------------------------
// user
import {Signin} from "#pages/user/Signin";
import {Signup} from "#pages/user/Signup";
import {Signout} from "#pages/user/Signout";

import {RequestPassword} from "#pages/user/RequestPassword";

//import {Dashboard } from "#pages/Dashboard";
import {MyAccount } from "#pages/user/MyAccount";

import {MyReservations } from "#pages/user/MyReservations";
  import {AddReservation as UserAddReservation } from "#pages/user/AddReservation";

import {MyPackages } from "#pages/user/MyPackages";
  import {AddPackage as UserAddPackage} from "#pages/user/AddPackage";

import {MyDocuments } from "#pages/user/MyDocuments";

import {MyBasket } from "#pages/user/MyBasket";


import {Error} from "#pages/Error";

// ---------------------------------------------------------------------------------------
// admin
import {default as AdminRoot} from "#pages/admin/Root";
import {Accounts} from "#pages/admin/Accounts";
import {Resources} from "#pages/admin/Resources";
import {Packages} from "#pages/admin/Packages";

import {Reservations} from "#pages/admin/Reservations";
  import {AddReservation as AdminAddReservation} from "#pages/admin/AddReservation";

import {Documents} from "#pages/admin/Documents"

// -------------------------------------------------------------------------
import {BasketProvider} from "#utils/basket";

function App() { 

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,      
      children : [
        { path: "/",                  element: <Entreprise />  },  // ex Accueil
        { path: "/Entreprise",        element: <Entreprise />},        
        { path: "/Prestataires",      element: <Prestataires /> },                          
        { path: "/Services",          element: <Services /> },                  
        { path: "/Photos",            element: <Photos />},                 // Galerie photos

        { path: "/RequestPricing",    element: <RequestPricing />},     // demande de tarif

        { path: "/Contact",           element: <Contact />},

        { path: "/MentionsLegales",   element: <MentionsLegales />},
        { path: "/Rgpd",              element: <Rgpd />},

        { path: "/Signin",            element: <Signin />},        
          { path: "/Signup",            element: <Signup />},       
          { path: "/RequestPassword",    element: <RequestPassword />},                 
        { path: "/MyAccount",           element: <MyAccount />},       
        //{ path: "/Dashboard",         element: <Dashboard />},       
        { path: "/MyReservations",      element: <MyReservations />},       
          { path: "/MyReservations/Add",      element: <UserAddReservation />},               
        { path: "/MyPackages",          element: <MyPackages />},       
          { path: "/MyPackages/Add",          element: <UserAddPackage />},               
        { path: "/MyDocuments",         element: <MyDocuments />},               
        { path: "/Signout",           element: <Signout />},      

        { path: "/MyBasket",           element: <MyBasket />},

        { path: "*",                  element: <Error /> }
      ]  
    },
    {
      path: "/Admin",
      element: <AdminRoot />,      
      children : [
        { path: "/Admin/Accounts",    element: <Accounts />  },  

        { path: "/Admin/Resources",   element: <Resources />},                
        { path: "/Admin/Packages",    element: <Packages />},

        { path: "/Admin/Reservations",element: <Reservations /> },
          { path: "/Admin/Reservation/Add",      element: <AdminAddReservation />},               

        { path: "/Admin/Documents",   element: <Documents /> },                  

      ]  
    }  
  ]);
  
  return (
    //<React.StrictMode>       
    <>
    <BasketProvider>
      <RouterProvider router={router} />
    </BasketProvider>
    </>
    //</React.StrictMode>    
  );
}

export default App;