
export function register() {  

    if ('serviceWorker' in navigator) {      
      const registration = navigator.serviceWorker.register(
        '/version.js',
        {
          scope: './',
        }
      ).then ((reg) => {
        //console.log('SW registred! Scope :' + reg.scope); 
        //console.log(window.APP_VERSION); 
        /*setInterval(() => {
                console.log('Checked for update...');
                reg.update();                
        }, 1000 * 60 * 1);
        */
        reg.update();
      })
      .catch ((error) => {
        console.error(`Registration failed with ${error}`);
      });
    } else {
      console.log('no service worker');
    }
};
  