import React, { Fragment, useState, useEffect } from "react";

import { DataGrid } from '@mui/x-data-grid';
import { frFR as dataGridfrFR } from '@mui/x-data-grid/locales';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
//import CircularProgress from '@mui/material/CircularProgress';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import { useAuth } from '#utils/auth';
import { TitlePage } from "#components/TitlePage";
//import { LoadingIcon } from "#components/Loading";
import { TextError, TextMessage, TextTip } from '#components/TextInformation';
import { useApiPackages } from "#services/apiPackages";
import ScrollButtonToTop from '#components/ScrollButtonToTop'
import useFunctions from '#utils/functions';


export function Packages() {

    const { user } = useAuth();
    const api = useApiPackages();

    const { formatDate } = useFunctions();

    const [infoMessage, setInfoMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const [activeTab, setActiveTab] = useState(0);
    const [isWorking, setIsWorking] = useState(false);

    //--------------------------------------------------------------
    // définition des listes 

    const refCodeTabs = [
        { code: 'inuse', lib: "en cours", comment: "Abonnements en cours de validité" }
        , { code: 'wait', lib: "en attente", comment: "Abonnements en attente d\'activation" }
        , { code: 'history', lib: "Historique" }
    ]

    const baseColumns = [
        { field: 'upk_id', headerName: 'ID', width: 50, type: 'number' },
        { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 },
        {
            field: 'upk_date_start', headerName: 'Début', width: 120, type: 'date',
            valueFormatter: (params) => {
                return formatDate(params.value || '');
            }
        },
        {
            field: 'upk_date_end', headerName: 'Fin', width: 120, type: 'date',
            valueFormatter: (params) => {
                return formatDate(params.value || '');
            }
        },
        { field: 'upk_pass_solde', headerName: 'Solde', width: 60, type: 'number' },
        { field: 'upk_is_active', headerName: 'Actif', type: 'boolean', width: 100 },
        { field: 'upk_usr_libr', headerName: 'Compte', width: 200 },
        //{ field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 },
        //{ field: 'upk_usr_name', headerName: 'Nom', width: 150},
    ].map(
        // valeur commune      
        (col) => {
            col.headerClassName = 'app-theme--header';
            col.sortable = false;  // pb disableColumnSorting, on doit l'indiquer pour chaque column !      
            return col;
        }
    );

    // fonction pour init selon les tabs !
    const initFromTabs = (initValue) => {
        let arrayTabs = refCodeTabs.map((x) => x.code);
        let r = {};
        arrayTabs.forEach((tab) => { r[tab] = initValue; });
        return r;
    };

    const columns = initFromTabs(baseColumns);

    /*
    
        const columns = {
            // en cours
            cd0 : [  
            { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header'},
            { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.purchased.priData.lib || '';
                },
            },
            { field: 'upk_status_start', headerName: 'Début', width: 120, type :'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.startDateValidity); 
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
                }
            },
            { field: 'upk_status_end', headerName: 'Fin', width: 120, type :'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.endDateValidity); 
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
                }
            },
            { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 60, type : 'number', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.status.remainingPass;
                },
            },
            { field: 'upk_is_active', headerName: 'Actif', type : 'boolean', width: 100 , headerClassName: 'app-theme--header'},        
            { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
            { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
    //        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}
            
          ],
          // en attente
          cd1 : [        
            { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header'},
            { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.purchased.priData.lib || '';
                },
            },
            { field: 'upk_status_start', headerName: 'Début', width: 120, type : 'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.startDateValidity) ;
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
                }
            },
            { field: 'upk_status_end', headerName: 'Fin', width: 120, type :'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.endDateValidity);
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
                }
            },
            { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120, type :'number', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.status.remainingPass;
                },
            },
            { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150 , headerClassName: 'app-theme--header'},
            { field: 'upk_usr_name', headerName: 'Nom', width: 150 , headerClassName: 'app-theme--header'},
    //        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250 , headerClassName: 'app-theme--header'}        
          ],
          // historique
          cd2 : [
            { field: 'upk_id', headerName: 'ID', width: 50, type : 'number', headerClassName: 'app-theme--header' },
            { field: 'upk_pri_lib', headerName: 'Abonnement', width: 280 , headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.purchased.priData.lib || '';
                },
            },
            { field: 'upk_status_start', headerName: 'Début', width: 120, type : 'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.startDateValidity) || null;
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.startDateValidity || '' );
                }
            },
            { field: 'upk_status_end', headerName: 'Fin', width: 120, type : 'date', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return new Date(params.row.upk_data.status.endDateValidity) || null;
                },
                valueFormatter: (params) => {
                    return formatDate(params.value || '') ; // formatDate(params.row.upk_data.status.endDateValidity || '' );
                }
            },
            { field: 'upk_status_remaining_pass', headerName: 'Solde', width: 120, type :'number', headerClassName: 'app-theme--header',
                valueGetter: (params) => {
                    return params.row.upk_data.status.remainingPass;
                }
            },
            { field: 'upk_usr_firstname', headerName: 'Prénom', width: 150, headerClassName: 'app-theme--header'},
            { field: 'upk_usr_name', headerName: 'Nom', width: 150, headerClassName: 'app-theme--header'},
    //        { field: 'upk_usr_email', headerName: 'Email / Login', width: 250, headerClassName: 'app-theme--header' }        
          ]
        };       
      */

    //--------------------------------------------------------------            
    const [isTabLoaded, setIsTabLoaded] = useState(initFromTabs(false));
    const [data, setData] = useState(initFromTabs({}));

    //--------------------------------------------------------------        
    const [paginationModel, setPaginationModel] = useState(initFromTabs({
        page: 0,
        pageSize: 25
    }));
    const [filterModel, setFilterModel] = useState(initFromTabs({
        items: []
    }));

    const [rowCount, setRowCount] = useState(initFromTabs(0));

    const onPaginationModelChange = (newPaginationModel) => {
        let keyTab = refCodeTabs[activeTab].code;
        let temp = { ...paginationModel };
        temp[keyTab] = newPaginationModel;
        setPaginationModel(temp);
    };

    const onFilterModelChange = (newFilterModel) => {
        let keyTab = refCodeTabs[activeTab].code;
        let temp = { ...filterModel };
        temp[keyTab] = newFilterModel;
        setFilterModel(temp);
    };

    //--------------------------------------------------------------    
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const getRowId = (data) => {
        return data.upk_id;
    }

    useEffect(() => {
        let keyTab = refCodeTabs[activeTab].code;

        if (user) { // && !isTabLoaded[keyTab]) { 
            setIsWorking(true);
            api.getData(keyTab, paginationModel[keyTab], filterModel[keyTab])
                .then((json) => {
                    setIsWorking(false);
                    let tempL = { ...isTabLoaded };
                    let tempD = { ...data };
                    let tempRC = { ...rowCount }
                    tempL[keyTab] = true;
                    if (json.returnCode === 0) {
                        tempD[keyTab] = json.returnData;
                        tempRC[keyTab] = json.returnTotalDataCount;
                        setErrorMessage('');
                    } else {
                        tempD[keyTab] = {};
                        tempRC[keyTab] = 0;
                        setErrorMessage(json.returnMsg || 'Erreur récupération des réservations');
                    }
                    setData(tempD);
                    setRowCount(tempRC);
                    setIsTabLoaded(tempL);
                });
        }

    }, [user, activeTab, paginationModel, filterModel]);

    //--------------------------------------------------------------        
    const getData = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return (data[key] && data[key].length > 0) ? data[key] : null;
    }
    const getCol = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return (columns[key] && columns[key].length > 0) ? columns[key] : null;
    }
    const getCommentActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].comment;
        return key ? key : '';
    }
    const getRowCountActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return rowCount[key];
    }
    const getPaginationModelActiveTab = (tabId) => {
        let key = refCodeTabs[tabId].code;
        return paginationModel[key];
    }

    return (
        <Fragment>
            <TitlePage titlePage="Abonnements" />

            {infoMessage !== '' && <TextMessage>{infoMessage}</TextMessage>}
            {errorMessage !== '' && <TextError>{errorMessage}</TextError>}

            <Container sx={{ pt: 2, pb: 4 }} maxWidth="xl">

                {/* les tabs  */}
                <Box sx={{ mb: 2, py: 0, px: 0, borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                        {
                            refCodeTabs.map((value, index) => (
                                <Tab key={index} label={value.lib} wrapped={true} />
                            ))
                        }
                    </Tabs>
                </Box>
                <TextTip>{getCommentActiveTab(activeTab)}</TextTip>

                {/* les datas */}
                {/*getData(activeTab) && */
                    <DataGrid
                        localeText={dataGridfrFR.components.MuiDataGrid.defaultProps.localeText}
                        loading={isWorking}
                        getRowId={getRowId}
                        rows={getData(activeTab) || []}
                        rowCount={getRowCountActiveTab(activeTab)}
                        columns={getCol(activeTab)}
                        density='compact'
                        disableColumnSelector={true}

                        filterMode="server"
                        onFilterModelChange={onFilterModelChange}

                        pageSizeOptions={[10, 25, 50, 100]}
                        paginationModel={getPaginationModelActiveTab(activeTab)}
                        onPaginationModelChange={onPaginationModelChange}
                        paginationMode="server"
                    />
                }

                {!getData(activeTab) && <TextMessage>Aucun abonnement</TextMessage>}

            </Container>

            <ScrollButtonToTop />

        </Fragment>
    );
};