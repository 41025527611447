import { useAuth } from '../utils/auth';

export function useApiAccounts() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/user';

  const getData = async function (filter, paginationModel, filterModel) {

    paginationModel = paginationModel || {page : 0, pageSize : 25};
    let page = paginationModel.page || 0 ;
    let pageSize =  paginationModel.pageSize || 25;
    
    let aFilters = [], aSorters = [];    
    let userFilter = [];

    filterModel = filterModel || {items : []};
    
    if (filterModel.items.length>0) {      
      filterModel.items.forEach(element => {
        userFilter.push({
          property : element.field,
          operator : element.operator,
          value : element.value
        });
      });      
    }


    var getFilters = '';

    // actif
    if (filter==='inuse') {
      aFilters = [
        {property:"usr_is_active",value:1,operator:"=="},
        {property:"usr_is_accepted",value:1,operator:"=="}
      ];
      aSorters = [{property:"usr_id", direction: 'ASC'}];
    }

    // en attente
    if (filter==='wait') {      
      aFilters = [
        {property:"usr_is_active",value:1,operator:"=="},
        {property:"usr_is_accepted",value:0,operator:"=="}
      ];
      aSorters = [{property:"usr_id", direction: 'ASC'}];
    }

    // non actif
    if (filter==='notinuse') {
      aFilters = [
        {property:"usr_is_active",value:0,operator:"=="}
      ];
      aSorters = [{property:"usr_id", direction: 'ASC'}];      
    }

    if (userFilter.length>0) {
      aFilters = aFilters.concat(userFilter);
    }

    return fetch(resource  + '?start=' + (page * pageSize) + '&count=' + pageSize + '&filters=' + JSON.stringify(aFilters) + '&sorters=' + JSON.stringify(aSorters), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"

    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }      
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  };


  const updateData = async function (data) {
    return fetch(resource + '/' + auth.getId(), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "PUT",
      body  : JSON.stringify(data)
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }    
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;              
    })
  };

  return {
    getData,
    updateData
  };
}
