import { useAuth } from '../utils/auth';
import dayjs from 'dayjs';
import "dayjs/locale/fr";

export function useApiReservations() {

  const auth = useAuth();
  const resource = auth.apiBaseUrl + '/reservation';

  const getData = async function (filter, paginationModel, filterModel) {

    dayjs().locale('fr');
    //console.log(paginationModel, filterModel);

    paginationModel = paginationModel || {page : 0, pageSize : 25};
    let page = paginationModel.page || 0 ;
    let pageSize =  paginationModel.pageSize || 25;
    
    let aFilters = [], aSorters = [];    
    let userFilter = [];

    filterModel = filterModel || {items : []};
    
    if (filterModel.items.length>0) {      
      filterModel.items.forEach(element => {
        userFilter.push({
          property : element.field,
          operator : element.operator,
          value : element.value
        });
      });      
    }

    if (filter==='today') {
      aFilters = [{property:"urv_status",value:0,operator:"=="}, {property:"urv_date", value: dayjs().format('YYYY-MM-DD')}];
      aSorters = [{property:"urv_date", direction: 'ASC'},{property:"urv_tis_id",direction:"ASC"}];
    }

    if (filter==='thisweek') {      
      aFilters = [
        {property:"urv_status",value:0,operator:"=="}, 
        {open : "(", property:"urv_date", value: dayjs().startOf('week').format('YYYY-MM-DD'), operator: ">="},
        {close : ")", property:"urv_date", value: dayjs().endOf('week').format('YYYY-MM-DD'), operator:"<="}
      ];
      aSorters = [{property:"urv_date", direction: 'ASC'},{property:"urv_tis_id",direction:"ASC"}];

    }  

    if (filter==='nextweek') {      
      aFilters = [
        {property:"urv_status",value:0,operator:"=="}, 
        {open : "(", property:"urv_date", value: dayjs().startOf('week').add(7,'day').format('YYYY-MM-DD'), operator: ">="},
        {close : ")", property:"urv_date", value: dayjs().endOf('week').add(7,'day').format('YYYY-MM-DD'), operator:"<="}
      ];
      aSorters = [{property:"urv_date", direction: 'ASC'},{property:"urv_tis_id",direction:"ASC"}];

    }      
    // A venir
    if (filter==='future') {
      aFilters = [{property:"urv_status",value:0,operator:"=="}, {property:"urv_date", value: dayjs().format('YYYY-MM-DD'), operator:">"}];
      aSorters = [{property:"urv_date", direction: 'ASC'},{property:"urv_tis_id",direction:"ASC"}];
    }

    // Historique
    if (filter==='history') {
      aFilters = [{property:"urv_status",value:100,operator:"=="}];
      aSorters = [{property:"urv_date", direction: 'DESC'},{property:"urv_tis_id",direction:"ASC"}];
    }

    if (userFilter.length>0) {
      aFilters = aFilters.concat(userFilter);
    }

    return fetch(resource  + '?start=' + (page * pageSize) + '&count=' + pageSize + '&filters=' + JSON.stringify(aFilters) + '&sorters=' + JSON.stringify(aSorters), {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "GET"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;      
    })
  };


  const deleteData = async function(urvId) {
    return fetch(resource + '/' + urvId, {
      signal : AbortSignal.timeout(30000),
      headers: {
        "Content-Type" : "application/json",
        "Authorization": "Bearer " + (auth.user ? auth.user.token : "")                    
      },
      method: "DELETE"
    }).then((res) => {
      if (res.ok) {                
        return res.json();
      } else if (res.status===401) {
        return {          
          returnCode : -401,
          returnMsg : "Acces non autorisé, vous devez vous connecter" //res.statusText
        }  ;        
      } else {  
        return {          
          returnCode : -2,
          returnMsg : res.statusText
        }  ;
      }     
    }).catch(error => {
      return {          
        returnCode : -500,
        returnMsg : (error.response || error.request || error.message)
      }  ;    
    })
  };

  return {
    getData,
    deleteData
  };
}
